import React ,{useState,useEffect} from 'react'
import Navbar from './Navbar'
import Carosuel from './Carosuel'
import Videos from './Videos'
import Footer from './Footer'
import './Loader.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'


const Home = () => {

  const urlParams = new URLSearchParams(window.location.search);
  // const msisdn = urlParams.get("msisdn");

  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const navigate=useNavigate()

//   const checkSubscriptionStatus = () => {
//     axios.get(`http://localhost:2023/checkuser/${msisdn}`)
//         .then(response => {
//             setSubscriptionStatus(response.data.status);
            
//             if (response.data.status == '0') {
//              navigate('/')
//             }
//         })
//         .catch(error => {
//             console.error('Error fetching subscription status:', error);
//         });
// }
//       useEffect(()=>{
//         checkSubscriptionStatus()
//       },[])

  return (
    <div className='bg-black'>
           <Navbar />
            <Carosuel />
            <Videos />
            <Footer />
    </div>  
  )
}

export default Home
