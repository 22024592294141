import React from 'react'

const Footer = () => {
  return (
    <div className='gradient-bg w-full mt-2 h-[50px]'>
      
    </div>
  )
}

export default Footer
