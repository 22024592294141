import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Home from './components/Home'
import { BrowserRouter, Routes,Route } from 'react-router-dom'
import SingleVideo from './components/SingleVideo'
import Login from './components/Login'
const App = () => {

  return (
    <div>
     <BrowserRouter>
       <Routes>
        <Route path='/login' element={<Login/>}/>
          <Route path='/' element={<Home/>}/>
           <Route path='/videos/:id' element={<SingleVideo/>}/>
       </Routes>
     </BrowserRouter>
     
    </div>
  )
}

export default App
