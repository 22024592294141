import React, {useEffect,useState} from "react";
import login from '../assets/fitofyy_logo.png'
import logo from '../assets/login.png'
import axios from "axios";
import { useNavigate } from "react-router-dom";


const Login = () => {

    const [msisdn, setMsisdn] = useState('');
    const navigate=useNavigate()

    const handleMsisdnChange = (e) => {
        setMsisdn(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault(); 
        axios.get(`/checkuser/${msisdn}`)
            .then(response => {
            
                if (response.data.status == '1') {
                    navigate(`/`); 
                } else {
                    navigate('/'); 
                }
            })
            .catch(error => {
                console.error('Error checking user:', error);
                navigate('/');
            });
    };

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
          document.body.style.overflow = 'unset';
        };
      }, []);
  return (
    
<div className="gradient-bg h-[1200px]">
<div className=" container mx-auto rounded-lg h-auto flex justify-center px-5  py-10">
<div class="max-w-sm bg-gray-100 flex-col  rounded-lg shadow dark:bg-gray-100 ">
<a >
        <img  src={logo} alt="" className="w-full h-[250px] rounded-t-lg" />
    </a>
    <a>
        <img  src={login} alt="" className="w-full" />
    </a>
 
    <div class="p-5">
        

    <form class="space-y-3" onSubmit={handleSubmit} >

        <div>
            <h1 className="text-xl text-gray-800 font-bold">Sign in to watch unlimited videos!</h1>
            <label for="msisdn" class="block mb-2 mt-5 text-sm font-medium text-gray-600 dark:text-gray-600">Enter your number</label>
            <input type="number" name="msisdn" id="msisdn" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-300 dark:placeholder-gray-400 dark:text-gray-900" placeholder="**********89" required
            
            value={msisdn} 
            onChange={handleMsisdnChange} 
            />
        </div>
      
        
        <button type="submit" class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Login to your account</button>
     
    </form>
</div>

      
    </div>
</div>
    </div>




  );
};

export default Login;
